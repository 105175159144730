<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Arqueo de Caja - Nuevo</strong>
          </CCardHeader>
          <CCardBody>

                <div class="w-100" v-if="state == 1">

                    <b-form id="Form" @submit.prevent="ValidateAdd">
                      <b-row>
                          <b-col md="2">
                              <b-form-group label="Codigo:">
                              <b-form-input type="text" class="text-center" readonly v-model="newbox.code" ></b-form-input>
                              <small v-if="newerrors.id_correlative" class="form-text text-danger">Ingrese un código</small >
                              </b-form-group>
                          </b-col>

                          <b-col md="2">
                              <b-form-group label="Fecha Inicio:">
                              <b-form-input disabled :min="min_date" type="date" class="text-center"  v-model="newbox.start_date" ></b-form-input>
                              </b-form-group>
                          </b-col>

                          <b-col md="2">
                              <b-form-group label="Saldo Inicial (Soles) :">
                              <b-form-input type="number" step="any" class="text-right" v-model="newbox.initial_balance_pen" ></b-form-input>
                              <small v-if="newerrors.initial_balance_pen" class="form-text text-danger">Ingrese un monto</small >
                              </b-form-group>
                          </b-col>

                          <b-col md="2">
                              <b-form-group label="Saldo Inicial (Dolares) :">
                              <b-form-input type="number" step="any" class="text-right" v-model="newbox.initial_balance_usd" ></b-form-input>
                              <small v-if="newerrors.initial_balance_usd" class="form-text text-danger">Ingrese un monto</small >
                              </b-form-group>
                          </b-col>

                          <b-col md="2">
                              <b-form-group label="Saldo Inicial (P. Chilenos) :">
                              <b-form-input type="number" step="any" class="text-right" v-model="newbox.initial_balance_clp" ></b-form-input>
                              <small v-if="newerrors.initial_balance_clp" class="form-text text-danger">Ingrese un monto</small >
                              </b-form-group>
                          </b-col>

                          <b-col md="2">
                              <b-form-group label="Usuario :">
                              <b-form-input disabled  v-model="user.user" ></b-form-input>
                              </b-form-group>
                          </b-col>

                          <b-col md="12">
                              <b-form-group label="Observación :">
                              <b-form-textarea  v-model="newbox.observation" ></b-form-textarea>
                              </b-form-group>
                          </b-col>

                          <b-col md="5"></b-col>
                          <b-col md="2">
                              <b-form-group label=".">
                              <b-button type="submit" class="form-control btn btn-primary" variant="primary">INICIAR</b-button>
                              </b-form-group>
                          </b-col>
                      </b-row>
                    </b-form>
                </div>

                <div class="w-100" v-if="state == 2">

                    <b-form id="Form" @submit.prevent="Validate">
                            <b-row>
                                <b-col md="3">
                                    <b-form-group label="Codigo:">
                                    <b-form-input type="text" class="text-center" readonly v-model="box.code" ></b-form-input>
                                    </b-form-group>
                                </b-col>

                                <b-col md="3">
                                    <b-form-group label="Fecha Inicio:">
                                    <b-form-input type="date" class="text-center" readonly v-model="box.start_date" ></b-form-input>
                                    </b-form-group>
                                </b-col>

                                <b-col md="3">
                                    <b-form-group label="Fecha Final:">
                                    <b-form-input readonly type="date" :min="box.start_date" class="text-center" v-model="box.end_date" ></b-form-input>
                                    </b-form-group>
                                </b-col>

                                <b-col md="3">
                                    <b-form-group label="Usuario:">
                                    <b-form-input type="text" class="text-center" readonly v-model="box.user" ></b-form-input>
                                    </b-form-group>
                                </b-col>

                                <b-col md="12">
                                  <b-form-group>
                                    <label class="control-label">Observación: </label>
                                    <b-form-textarea v-model="box.observation" rows="2"></b-form-textarea>                 
                                  </b-form-group>
                                </b-col>

                                


                                <b-col md="12"></b-col>
                                <b-col md="6">
                                  
                                  <div class="w-100 p-2 text-center"><strong class="header">RESUMEN DE VENTAS</strong></div>
                                  <div class="table-responsive">
                                    <table class="table table-striped table-bordered w-100">
                                      <thead>
                                          <tr class="bg-danger">
                                            <td class="text-center" width="40%" ></td>
                                            <td class="text-center" width="20%">SOLES</td>
                                            <td class="text-center" width="20%">DOLARES</td>
                                            <td class="text-center" width="20%">PESOS</td>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          
                                          <tr class="bg-info">
                                            <td class="text-let pl-3" >Ventas al Contado</td>
                                            <td class="text-right" >{{box.total_counted_sales_pen}}</td>
                                            <td class="text-right" >{{box.total_counted_sales_usd}}</td>
                                            <td class="text-right" >{{box.total_counted_sales_clp}}</td>
                                          </tr>
                                          <tr class="bg-light text-dark">
                                            <td class="text-let pl-5" >Efectivo</td>
                                            <td class="text-right" >{{box.cash_pen}}</td>
                                            <td class="text-right" >{{box.cash_usd}}</td>
                                            <td class="text-right" >{{box.cash_clp}}</td>
                                          </tr>
                                          <tr class="bg-light text-dark">
                                            <td class="text-let pl-5" >Tarjeta</td>
                                            <td class="text-right" >{{box.debit_pen}}</td>
                                            <td class="text-right" >{{box.debit_usd}}</td>
                                            <td class="text-right" >{{box.debit_clp}}</td>
                                          </tr>
                                          <!-- <tr class="bg-light text-dark">
                                            <td class="text-let pl-5" >Tarjeta de Crédito</td>
                                            <td class="text-right" >{{box.credit_pen}}</td>
                                            <td class="text-right" >{{box.credit_usd}}</td>
                                            <td class="text-right" >{{box.credit_clp}}</td>
                                          </tr> -->
                                          <tr class="bg-light text-dark">
                                            <td class="text-let pl-5" >Transferencia de Fondos</td>
                                            <td class="text-right" >{{box.transfer_funds_pen}}</td>
                                            <td class="text-right" >{{box.transfer_funds_usd}}</td>
                                            <td class="text-right" >{{box.transfer_funds_clp}}</td>
                                          </tr>
                                          <tr class="bg-light text-dark">
                                            <td class="text-let pl-5" >Yape</td>
                                            <td class="text-right" >{{box.yape_pen}}</td>
                                            <td class="text-right" >{{box.yape_usd}}</td>
                                            <td class="text-right" >{{box.yape_clp}}</td>
                                          </tr>
                                          <tr class="bg-light text-dark">
                                            <td class="text-let pl-5" >Plin</td>
                                            <td class="text-right" >{{box.pilin_pen}}</td>
                                            <td class="text-right" >{{box.pilin_usd}}</td>
                                            <td class="text-right" >{{box.pilin_clp}}</td>
                                          </tr>
                                          <tr class="bg-light text-dark">
                                            <td class="text-let pl-5" >Vale de Consumo</td>
                                            <td class="text-right" >{{box.others_pen}}</td>
                                            <td class="text-right" >{{box.others_usd}}</td>
                                            <td class="text-right" >{{box.others_clp}}</td>
                                          </tr>
                                          <tr class="bg-light text-dark">
                                            <td class="text-let pl-5" >Creditos al Personal</td>
                                            <td class="text-right" >{{box.credits_staff_pen}}</td>
                                            <td class="text-right" >{{box.credits_staff_usd}}</td>
                                            <td class="text-right" >{{box.credits_staff_clp}}</td>
                                          </tr>
                                          <tr class="bg-info">
                                            <td class="text-let pl-3" >Ventas al Credito</td>
                                            <td class="text-right" >{{box.total_credit_sales_pen}}</td>
                                            <td class="text-right" >{{box.total_credit_sales_usd}}</td>
                                            <td class="text-right" >{{box.total_credit_sales_clp}}</td>
                                          </tr>
                                          <tr class="bg-success">
                                            <td class="text-let" >Total de Ventas del Periodo</td>
                                            <td class="text-right" >{{box.total_sales_period_pen}}</td>
                                            <td class="text-right" >{{box.total_sales_period_usd}}</td>
                                            <td class="text-right" >{{box.total_sales_period_clp}}</td>
                                          </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </b-col>


                                <b-col md="6">
                                  <div class="w-100 p-2 text-center"><strong class="header">ESTADO DE CAJA</strong></div>
                                  <div class="table-responsive">
                                    <table class="table table-striped table-bordered w-100">
                                      <thead>
                                          <tr class="bg-danger">
                                            <td class="text-center" width="55%" ></td>
                                            <td class="text-center" width="15%">SOLES</td>
                                            <td class="text-center" width="15%">DOLARES</td>
                                            <td class="text-center" width="15%">PESOS</td>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr class="bg-success">
                                            <td class="text-let" >Saldo Inicial</td>
                                            <td class="text-right" > 
                                              <!-- UpdateInitialBalance -->
                                              <b-button @click="ModalChangeInitialBalance" class="form-control text-right" variant="primary" type="button">{{box.initial_balance_pen}}</b-button>
                                            </td>
                                            <td class="text-right" >
                                              <b-button @click="ModalChangeInitialBalance" class="form-control text-right" variant="primary" type="button">{{box.initial_balance_usd}}</b-button>
                                            </td>
                                            <td class="text-right" >
                                              <b-button @click="ModalChangeInitialBalance" class="form-control text-right" variant="primary" type="button">{{box.initial_balance_clp}}</b-button>
                                            </td>
                                          </tr>
                                          <tr class="bg-success">
                                            <td class="text-let" >Ingresos por Ventas al Contado - Efectivo</td>
                                            <td class="text-right" >{{box.cash_pen}}</td>
                                            <td class="text-right" >{{box.cash_usd}}</td>
                                            <td class="text-right" >{{box.cash_clp}}</td>
                                          </tr>
                                          <tr class="bg-success">
                                            <td class="text-let" >Ingresos por cobros de Cuentas - Efectivo</td>
                                            <td class="text-right" >{{box.total_income_collections_pen}}</td>
                                            <td class="text-right" >{{box.total_income_collections_usd}}</td>
                                            <td class="text-right" >{{box.total_income_collections_clp}}</td>
                                          </tr>
                                          <tr class="bg-success">
                                            <td class="text-let" >Egreso por pagos de Cuentas - Efectivo</td>
                                            <td class="text-right" >{{box.total_payment_expenses_pen}}</td>
                                            <td class="text-right" >{{box.total_payment_expenses_usd}}</td>
                                            <td class="text-right" >{{box.total_payment_expenses_clp}}</td>
                                          </tr>
                                          <tr class="bg-success">
                                            <td class="text-let" >Ingresos Extraordinarios - Efectivo</td>
                                            <td class="text-right" >{{box.total_extraordinary_income_pen}}</td>
                                            <td class="text-right" >{{box.total_extraordinary_income_usd}}</td>
                                            <td class="text-right" >{{box.total_extraordinary_income_clp}}</td>
                                          </tr>
                                          <tr class="bg-success">
                                            <td class="text-let" >Egresos Extraordinarios - Efectivo</td>
                                            <td class="text-right" >{{box.total_extraordinary_expenses_pen}}</td>
                                            <td class="text-right" >{{box.total_extraordinary_expenses_usd}}</td>
                                            <td class="text-right" >{{box.total_extraordinary_expenses_clp}}</td>
                                          </tr>
                                          <tr class="bg-dark">
                                            <td class="text-let" >Saldo Final</td>
                                            <td class="text-right" >{{box.total_income_pen}}</td>
                                            <td class="text-right" >{{box.total_income_usd}}</td>
                                            <td class="text-right" >{{box.total_income_clp}}</td>
                                          </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </b-col>

                                <b-col md="3"></b-col>
                                <b-col md="2">
                                    <b-button type="button" @click="ModalBoxSummaryShow" class="form-control" variant="info">Resumen de Caja</b-button>
                                </b-col>
                                <b-col md="2">
                                    <b-button type="button" @click="ExportExcel" class="form-control" variant="success">Excel</b-button>
                                </b-col>
                                <b-col md="2">
                                    <b-button type="submit" class="form-control" variant="primary">Cerrar Caja</b-button>
                                </b-col>
                            </b-row>
                    </b-form>

                </div> 
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

      <b-modal size="md" hide-footer v-model="modal_initial_balance" class="w-100" title="Modificar Saldo Inicial">
        <b-form id="Form" @submit.prevent="ChangeInitialBalance">
            <b-row>
                <b-col md="4">
                    <b-form-group label="Soles:">
                      <b-form-input type="number" step="any" class="text-right" v-model="box.initial_balance_pen"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group label="Dolares:">
                      <b-form-input type="number" step="any" class="text-right" v-model="box.initial_balance_usd"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group label="Pesos:">
                      <b-form-input type="number" step="any" class="text-right" v-model="box.initial_balance_clp"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="8">
                    <b-form-group label="Motivo:">
                      <b-form-input type="text"  class="text-left" v-model="box.reason"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group label="Clave:">
                      <b-form-input type="password"  class="text-left" v-model="box.password"></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col md="4"></b-col>
                <b-col md="4">
                  <b-form-group label="">
                      <b-button class="form-control" type="submit" variant="primary">Guardar</b-button>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
      </b-modal>
    <BoxSummary/>
  </div>
</template>
<style scoped>
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
</style>
<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
var moment = require("moment");
import EventBus from "@/assets/js/EventBus";
import BoxSummary from './components/Summary'
export default {
  name: "CashAdd",
  components:{
    BoxSummary,
  },
  data() {
    return {
      module: "Box",
      role: 2,
      state: 0,
      min_date:'',
      modal_initial_balance: false,
      //new
      newbox: {

          id_box : '',
          id_user : '',
          code : '',
          start_date : moment(new Date()).local().format("YYYY-MM-DD"),
          end_date : moment(new Date()).local().format("YYYY-MM-DD"),
          observation : '',
          initial_balance_pen : (0).toFixed(2),
          cash_pen : '',
          deposit_account_pen : '',
          debit_pen : '',
          credit_pen : '',
          transfer_funds_pen : '',
          yape_pen : '',
          pilin_pen : '',
          credits_staff_pen: '',
          others_pen : '',
          total_counted_sales_pen : '',
          total_credit_sales_pen : '',
          total_sales_period_pen : '',
          total_income_collections_pen : '',
          total_payment_expenses_pen:'',
          total_extraordinary_income_pen : '',
          total_extraordinary_expenses_pen : '',
          total_income_pen : '',
          initial_balance_usd : (0).toFixed(2),
          cash_usd : '',
          deposit_account_usd : '',
          debit_usd : '',
          credit_usd : '',
          transfer_funds_usd : '',
          yape_usd : '',
          pilin_usd : '',
          credits_staff_usd: '',
          others_usd : '',
          total_counted_sales_usd : '',
          total_credit_sales_usd : '',
          total_sales_period_usd : '',
          total_income_collections_usd : '',
          total_payment_expenses_usd:'',
          total_extraordinary_income_usd : '',
          total_extraordinary_expenses_usd : '',
          total_income_usd : '',
          initial_balance_clp : (0).toFixed(2),
          cash_clp : '',
          deposit_account_clp : '',
          debit_clp : '',
          credit_clp : '',
          transfer_funds_clp : '',
          yape_clp : '',
          pilin_clp : '',
          credits_staff_clp: '',
          others_clp : '',
          total_counted_sales_clp : '',
          total_credit_sales_clp : '',
          total_sales_period_clp : '',
          total_income_collections_clp : '',
          total_payment_expenses_clp:'',
          total_extraordinary_income_clp : '',
          total_extraordinary_expenses_clp : '',
          total_income_clp : '',
          state : '1',


      },
      newerrors: {
        id_correlative: false,
        initial_balance_pen: false,
        initial_balance_usd: false,
      },
      validate_add: false,







      box: {
          id_box:0,
          id_user:0,
          id_correlative:0,
          cash_number:'',
          start_date:'',
          end_date:'',

          initial_balance_pen:(0).toFixed(2),
          cash_pen:(0).toFixed(2),
          deposit_account_pen:(0).toFixed(2),
          debit_pen:(0).toFixed(2),
          credit_pen:(0).toFixed(2),
          transfer_funds_pen:(0).toFixed(2),
          yape_pen:(0).toFixed(2),
          pilin_pen:(0).toFixed(2),
          others_pen:(0).toFixed(2),
          credits_staff_pen:(0).toFixed(2),
          total_counted_sales_pen:(0).toFixed(2),
          total_credit_sales_pen:(0).toFixed(2),
          total_sales_period_pen:(0).toFixed(2),
          total_income_collections_pen:(0).toFixed(2),
          total_payment_expenses_pen:(0).toFixed(2),
          total_extraordinary_income_pen:(0).toFixed(2),
          total_extraordinary_expenses_pen:(0).toFixed(2),
          total_income_pen:(0).toFixed(2),

          initial_balance_usd:(0).toFixed(2),
          cash_usd:(0).toFixed(2),
          deposit_account_usd:(0).toFixed(2),
          debit_usd:(0).toFixed(2),
          credit_usd:(0).toFixed(2),
          transfer_funds_usd:(0).toFixed(2),
          yape_usd:(0).toFixed(2),
          pilin_usd:(0).toFixed(2),
          others_usd:(0).toFixed(2),
          credits_staff_usd:(0).toFixed(2),
          total_counted_sales_usd:(0).toFixed(2),
          total_credit_sales_usd:(0).toFixed(2),
          total_sales_period_usd:(0).toFixed(2),
          total_income_collections_usd:(0).toFixed(2),
          total_payment_expenses_usd:(0).toFixed(2),
          total_extraordinary_income_usd:(0).toFixed(2),
          total_extraordinary_expenses_usd:(0).toFixed(2),
          total_income_usd:(0).toFixed(2),



          initial_balance_clp:(0).toFixed(2),
          cash_clp:(0).toFixed(2),
          deposit_account_clp:(0).toFixed(2),
          debit_clp:(0).toFixed(2),
          credit_clp:(0).toFixed(2),
          transfer_funds_clp:(0).toFixed(2),
          yape_clp:(0).toFixed(2),
          pilin_clp:(0).toFixed(2),
          others_clp:(0).toFixed(2),
          credits_staff_clp:(0).toFixed(2),
          total_counted_sales_clp:(0).toFixed(2),
          total_credit_sales_clp:(0).toFixed(2),
          total_sales_period_clp:(0).toFixed(2),
          total_income_collections_clp:(0).toFixed(2),
          total_payment_expenses_clp:(0).toFixed(2),
          total_extraordinary_income_clp:(0).toFixed(2),
          total_extraordinary_expenses_clp:(0).toFixed(2),
          total_income_clp:(0).toFixed(2),

          observation:'',
          state:0,
          

          reason:'',
          password:'',
      },

      //errors
      errors: {
        id_correlative: false,
        initial_balance_pen: false,
        initial_balance_usd: false,
        initial_balance_clp: false,
      },
      
      validate: false,


    };
  },
  mounted() {
    this.ValidateOpenBox();
  },
  methods: {
    ValidateOpenBox,

    GetCorrelative,
    Addcash,
    ValidateAdd,
    ExportExcel,
    ExportPDF,

    CalculateBox,
    CloseBox,
    Validate,
    UpdateInitialBalance,

    ModalBoxSummaryShow,
    ModalChangeInitialBalance,
    ChangeInitialBalance,

  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  
  },
};

function ModalChangeInitialBalance() {
   this.modal_initial_balance = true;
}

function ChangeInitialBalance() {
  if (this.box.initial_balance_pen == '') {
    Swal.fire({ icon: 'warning', text: 'Ingrese un saldo inicial', timer: 2000,}); 
    return false;
  }
  if (this.box.initial_balance_usd == '') {
    Swal.fire({ icon: 'warning', text: 'Ingrese un saldo inicial', timer: 2000,}); 
    return false;
  }
  if (this.box.initial_balance_clp == '') {
    Swal.fire({ icon: 'warning', text: 'Ingrese un saldo inicial', timer: 2000,}); 
    return false;
  }

  if (this.box.reason == '') {
    Swal.fire({ icon: 'warning', text: 'Ingrese un motivo', timer: 2000,}); 
    return false;
  }
  if (this.box.password == '') {
    Swal.fire({ icon: 'warning', text: 'Ingrese su clave', timer: 2000,}); 
    return false;
  }

  let me = this;
  let url = this.url_base + "box/update-initial-balance";
  let data = {
    id_box: this.box.id_box,
    initial_balance_pen: this.box.initial_balance_pen,
    initial_balance_usd: this.box.initial_balance_usd,
    initial_balance_clp: this.box.initial_balance_clp,
    reason: this.box.reason,
    password: this.box.password,
    id_user : me.user.id_user
  };
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {token: this.token,module: this.module,role: this.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.box.reason = '';
        me.box.password = '';
        me.modal_initial_balance = false;
        Swal.fire({ icon: 'success', text: response.data.message , timer: 3000,})
        me.ValidateOpenBox();
      } else {
        Swal.fire({ icon: 'error', text: response.data.message , timer: 3000,})
      }
    })
  
}

function UpdateInitialBalance() {
  
  let me = this;
  let url = this.url_base + "box/update-initial-balance";
  let data = this.box;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {token: this.token,module: this.module,role: this.role},
  })
  .then(function (response) {
    if (response.data.status == 200) {
        me.CalculateBox();
    }
  })
}

function ModalBoxSummaryShow() {

  let data = {
    role: this.role,
    id_box: this.box.id_box,
  };
  EventBus.$emit('ModalBoxSummaryShow',data);
  
}

function ValidateOpenBox() {

  let me = this;
  let url = this.url_base + "box/validate-open-box";
  let data = {
    id_user : me.user.id_user
  };
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {token: this.token,module: this.module,role: this.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.box.id_box = response.data.result.id_box;
        me.box.id_user = response.data.result.id_user;
        me.box.id_correlative = response.data.result.id_correlative;
        me.box.code = response.data.result.code;
        me.box.start_date = response.data.result.start_date;
        me.box.end_date = moment(new Date()).local().format("YYYY-MM-DD");
        me.box.observation = response.data.result.observation;
        me.box.state = response.data.result.state;
        me.box.user = response.data.result.user;
          
        me.state = 2;
        me.CalculateBox();
      }else{
        me.state = 1;
        me.GetCorrelative();
      }
    })
}

function GetCorrelative() {
  let me = this;
  let url = this.url_base + "get-correlative/Box";
  axios({
    method: "GET",
    url: url,
    headers: {token: this.token,module: this.module,role: this.role},
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.newbox.id_correlative = response.data.result.id_correlative;
      me.newbox.code = response.data.result.number;
    } 
  })
}

function ValidateAdd() {
  this.newerrors.id_correlative = this.newbox.id_correlative.length == 0 ? true : false;
  this.newerrors.initial_balance_pen = this.newbox.initial_balance_pen.length == 0 ? true : false;
  this.newerrors.initial_balance_usd = this.newbox.initial_balance_usd.length == 0 ? true : false;
  this.newerrors.initial_balance_clp = this.newbox.initial_balance_clp.length == 0 ? true : false;
  

  if (this.newerrors.id_correlative) { this.validate_add = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.newerrors.initial_balance_pen) { this.validate_add = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.newerrors.initial_balance_usd) { this.validate_add = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.newerrors.initial_balance_clp) { this.validate_add = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }


  let me = this;
  Swal.fire({
    title: "Esta seguro de inicar caja ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.Addcash(me);
    }
  });

}

function Addcash() {

  let me = this;
  me.newbox.id_user = me.user.id_user;
  let url = me.url_base + "box/add";
  let data = me.newbox;

  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {"Content-Type": "application/json", token: me.token, module: me.module, role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 201) {
        Swal.fire({ icon: 'success', text: response.data.message , timer: 3000,})
        me.ValidateOpenBox();
      } else {
        Swal.fire({ icon: 'error', text: response.data.message , timer: 3000,})
      }
    })
}


function CalculateBox() {
  let me = this;
  let url = this.url_base + "box/calculate-box";
  let data = {
    id_box : me.box.id_box,
    end_date : me.box.end_date,
  }
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {token: this.token, module: this.module, role: this.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {


          me.box.initial_balance_pen = response.data.result.initial_balance_pen;
          me.box.cash_pen = response.data.result.cash_pen;
          me.box.deposit_account_pen = response.data.result.deposit_account_pen;
          me.box.debit_pen = response.data.result.debit_pen;
          me.box.credit_pen = response.data.result.credit_pen;
          me.box.transfer_funds_pen = response.data.result.transfer_funds_pen;
          me.box.yape_pen = response.data.result.yape_pen;
          me.box.pilin_pen = response.data.result.pilin_pen;
          me.box.others_pen = response.data.result.others_pen;
          me.box.credits_staff_pen = response.data.result.credits_staff_pen;
          me.box.web_payment_pen = response.data.result.web_payment_pen;
          me.box.total_counted_sales_pen = response.data.result.total_counted_sales_pen;
          me.box.total_credit_sales_pen = response.data.result.total_credit_sales_pen;
          me.box.total_sales_period_pen = response.data.result.total_sales_period_pen;
          me.box.total_income_collections_pen = response.data.result.total_income_collections_pen;
          me.box.total_payment_expenses_pen = response.data.result.total_payment_expenses_pen;
          me.box.total_extraordinary_income_pen = response.data.result.total_extraordinary_income_pen;
          me.box.total_extraordinary_expenses_pen = response.data.result.total_extraordinary_expenses_pen;
          me.box.total_income_pen = response.data.result.total_income_pen;

          me.box.initial_balance_usd = response.data.result.initial_balance_usd;
          me.box.cash_usd = response.data.result.cash_usd;
          me.box.deposit_account_usd = response.data.result.deposit_account_usd;
          me.box.debit_usd = response.data.result.debit_usd;
          me.box.credit_usd = response.data.result.credit_usd;
          me.box.transfer_funds_usd = response.data.result.transfer_funds_usd;
          me.box.yape_usd = response.data.result.yape_usd;
          me.box.pilin_usd = response.data.result.pilin_usd;
          me.box.others_usd = response.data.result.others_usd;
          me.box.credits_staff_usd = response.data.result.credits_staff_usd;
          me.box.web_payment_usd = response.data.result.web_payment_usd;
          me.box.total_counted_sales_usd = response.data.result.total_counted_sales_usd;
          me.box.total_credit_sales_usd = response.data.result.total_credit_sales_usd;
          me.box.total_sales_period_usd = response.data.result.total_sales_period_usd;
          me.box.total_income_collections_usd = response.data.result.total_income_collections_usd;
          me.box.total_payment_expenses_usd = response.data.result.total_payment_expenses_usd;
          me.box.total_extraordinary_income_usd = response.data.result.total_extraordinary_income_usd;
          me.box.total_extraordinary_expenses_usd = response.data.result.total_extraordinary_expenses_usd;
          me.box.total_income_usd = response.data.result.total_income_usd;


          me.box.initial_balance_clp = response.data.result.initial_balance_clp;
          me.box.cash_clp = response.data.result.cash_clp;
          me.box.deposit_account_clp = response.data.result.deposit_account_clp;
          me.box.debit_clp = response.data.result.debit_clp;
          me.box.credit_clp = response.data.result.credit_clp;
          me.box.transfer_funds_clp = response.data.result.transfer_funds_clp;
          me.box.yape_clp = response.data.result.yape_clp;
          me.box.pilin_clp = response.data.result.pilin_clp;
          me.box.others_clp = response.data.result.others_clp;
          me.box.credits_staff_clp = response.data.result.credits_staff_clp;
          me.box.web_payment_clp = response.data.result.web_payment_clp;
          me.box.total_counted_sales_clp = response.data.result.total_counted_sales_clp;
          me.box.total_credit_sales_clp = response.data.result.total_credit_sales_clp;
          me.box.total_sales_period_clp = response.data.result.total_sales_period_clp;
          me.box.total_income_collections_clp = response.data.result.total_income_collections_clp;
          me.box.total_payment_expenses_clp = response.data.result.total_payment_expenses_clp;
          me.box.total_extraordinary_income_clp = response.data.result.total_extraordinary_income_clp;
          me.box.total_extraordinary_expenses_clp = response.data.result.total_extraordinary_expenses_clp;
          me.box.total_income_clp = response.data.result.total_income_clp;

          

      } 
    })
}
















function ExportExcel() {

  let me = this;
  let url = this.url_base + "box-export-excel/"+this.box.id_box;
  window.open(url,'_blank');
}

function ExportPDF() {
  let me = this;
  let url = this.url_base + "box-export-pdf/"+this.box.id_box;
  window.open(url,'_blank');
}


////STATE 1







// STATE 2
function CloseBox() {
  let me = this;
  let url = me.url_base + "box/close";
  let data = me.box;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {"Content-Type": "application/json", token: me.token,module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        Swal.fire({ icon: 'success', text: response.data.message , timer: 3000,})
        me.ValidateOpenBox();
      } else {
        Swal.fire({ icon: 'error', text: response.data.message , timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function Validate() {
  this.errors.id_box = this.box.id_box.length == 0 ? true : false;
  this.errors.end_date = this.box.end_date.length == 0 ? true : false;

  if (this.errors.id_box) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.end_date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }

  let me = this;
  Swal.fire({
    title: 'Esta seguro de cerrar la caja?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, Estoy de Acuerdo!'
  }).then((result) => {
    if (result.isConfirmed) {
      me.CloseBox();
    }
  })


}
</script>
